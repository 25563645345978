import React from 'react'
import {
    CLEANING_STATUS_CLEAN,
    CLEANING_STATUS_DIRTY,
    CLEANING_STATUS_DO_NOT_DISTURB,
    CLEANING_STATUS_INSPECTION,
    CLEANING_STATUS_NO_SERVICE,
    CLEANING_STATUS_OUT_OF_SERVICE,
    CLEANING_STATUS_PREPARING,
    CLEANING_STATUS_PREPARING_PAUSE,
    CLEANING_STATUS_WAITING_FOR_CHECKOUT,
    OCCUPANCY_CHECKIN,
    OCCUPANCY_CHECKOUT,
    OCCUPANCY_TURNOVER
} from '@shared/txt-constants'
import { pickGuestCleaningActionIcon } from '@shared/cleaning-helpers'
import { Image } from 'react-native-web'
import moment from 'moment-timezone'
import { CleaningDateProps } from '../CleaningScheduleLocal'
import { useFeatureToggle } from '../../../../../../features'

const getBubbleColor = (props: CleaningDateProps) => {
    if (props.occupancy === OCCUPANCY_CHECKIN) {
        return 'specta-green'
    }
    const { cleaningStatus } = props
    switch (cleaningStatus) {
        case CLEANING_STATUS_CLEAN:
            return 'specta-green'
        case CLEANING_STATUS_DIRTY:
        case CLEANING_STATUS_PREPARING:
        case CLEANING_STATUS_PREPARING_PAUSE:
        case CLEANING_STATUS_INSPECTION:
        case CLEANING_STATUS_DO_NOT_DISTURB:
        case CLEANING_STATUS_OUT_OF_SERVICE:
            return 'specta-pink'
        case CLEANING_STATUS_NO_SERVICE:
            return 'specta-teal'
        case CLEANING_STATUS_WAITING_FOR_CHECKOUT:
            return 'specta-purple'
        default:
            return 'gray-500'
    }
}
export const DateBubble = (props: CleaningDateProps) => {
    const { isFeatureOn } = useFeatureToggle()
    const { date, cleaningStatus, occupancy, onClick } = props
    let showIcon = false
    const firstOrLast = [OCCUPANCY_TURNOVER, OCCUPANCY_CHECKIN, OCCUPANCY_CHECKOUT].includes(occupancy)
    if (!firstOrLast) {
        showIcon = true
    }

    const disabled = isFeatureOn('read-only-cleaning-schedule')

    const colorClass = getBubbleColor(props) + ' text-white'
    const cleaningActionIconCondition = () => {
        const icon_scaler = 1
        const cleaningActionIcon = pickGuestCleaningActionIcon(cleaningStatus, occupancy)
        if (!cleaningActionIcon || firstOrLast) return null
        return (
            <Image
                style={{
                    width: cleaningActionIcon.width! * icon_scaler,
                    height: cleaningActionIcon.height! * icon_scaler,
                    alignSelf: 'center'
                    // marginRight: cleaningStatusIcon !== null ? SPACE_BETWEEN_ICONS : 0
                }}
                source={cleaningActionIcon.source!}
            />
        )
    }
    const isPast = props.date < moment().startOf('day').valueOf()
    return (
        <div
            className={`btn btn-${colorClass} rounded-circle mb-5 text-center ${
                !firstOrLast && !isPast && !disabled ? 'cursor-pointer' : 'disabled cursor-default'
            }`}
            style={{ minHeight: '7rem', minWidth: '7rem' }}
            onClick={() => {
                if (!firstOrLast && !isPast && !disabled) {
                    onClick(date, cleaningStatus)
                }
            }}>
            <div style={{ paddingTop: '0.3rem' }}>
                <div className="font-weight-bolder text-uppercase">{moment(date).format('ddd')}</div>
                <div className="font-weight-bolder" style={{ fontSize: '28px', lineHeight: '28px' }}>
                    {moment(date).format('DD')}
                </div>
                <div className="row mt-1 justify-content-center">{cleaningActionIconCondition()}</div>
            </div>
        </div>
    )
}
