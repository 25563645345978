import moment from 'moment-timezone'
import * as c from './txt-constants'

export function occupancyToHuman(occupancy, firstIsCapital = true) {
    if (occupancy === c.OCCUPANCY_CHECKIN) {
        if (firstIsCapital) {
            return 'Check-in'
        } else {
            return 'check-in'
        }
    }
    if (occupancy === c.OCCUPANCY_CHECKOUT) {
        if (firstIsCapital) {
            return 'Check-out'
        } else {
            return 'check-out'
        }
    }
    if (occupancy === c.OCCUPANCY_STAYOVER || occupancy === c.OCCUPANCY_STAYOVER_80) {
        if (firstIsCapital) {
            return 'Stayover'
        } else {
            return 'stayover'
        }
    }
    if (occupancy === c.OCCUPANCY_TURNOVER) {
        if (firstIsCapital) {
            return 'Check-out & check-in'
        } else {
            return 'check-out & check-in'
        }
    }

    if (occupancy === c.OCCUPANCY_VACANT) {
        if (firstIsCapital) {
            return 'Vacant'
        } else {
            return 'vacant'
        }
    }
    if (occupancy === c.OCCUPANCY_ALL) {
        if (firstIsCapital) {
            return 'All'
        } else {
            return 'all'
        }
    }

    return 'Unknown'
}

function ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

function isSameDate(date1, date2) {
    const d1 = moment(date1).startOf('day').valueOf()

    const d2 = moment(date2).startOf('day').valueOf()

    return d1 === d2
}

function daysBetween(startDate, endDate) {
    let start = moment(startDate)
    let end = moment(endDate)

    return end.diff(start, 'days')
}

function getGuestPostFix(nrOfGuests) {
    return nrOfGuests > 1 || nrOfGuests == 0 ? 'guests' : 'guest'
}

function getOccupancyText(booking, area, guestPostfix, date = moment(), isTaskRow = false) {
    date.startOf('day')

    const nrOfDaysStayed = daysBetween(booking.checkinDate, date)
    const lenghtOfStay = daysBetween(booking.checkinDate, booking.checkoutDate)
    const daysTillArrival = daysBetween(date, booking.checkinDate)

    if (isSameDate(booking.checkinDate, date.valueOf())) {
        if (isTaskRow) {
            return 'Arriving today'
        } else {
            return ucFirst(guestPostfix) + ' arriving today'
        }
    }
    if (moment(booking.checkoutDate).isSame(moment(date), 'day')) {
        return 'Checkout today'
    }
    if (daysTillArrival > 0) {
        return 'Arrival in ' + daysTillArrival + ' days'
    }
    return 'Day ' + (nrOfDaysStayed + 1) + ' of ' + lenghtOfStay
}

export function getBookingDisplay(booking, area, date = moment(), isTaskRow = false) {
    const bookingDisplay = {}

    if (area.bedTypes) {
        bookingDisplay.defaultBedSetup = area.bedTypes.join(', ')
    }

    if (booking && booking.status !== c.BOOKING_STATUS_BLOCKED) {
        const nrOfGuests = booking.nrOfGuests || Number.parseInt(booking.adults, 10) + Number.parseInt(booking.children, 10)
        const guestPostfix = getGuestPostFix(nrOfGuests)

        date.startOf('day')

        bookingDisplay.guestName = booking.guestName
        bookingDisplay.guestCount = nrOfGuests + ' ' + guestPostfix
        bookingDisplay.notes = booking.notes
        bookingDisplay.guestComments = booking.guestComments
        bookingDisplay.nrOfDaysStayed = daysBetween(booking.checkinDate, date)
        bookingDisplay.occupancyText = getOccupancyText(booking, area, guestPostfix, date, isTaskRow)
        bookingDisplay.occupancyState = occupancyToHuman(area.occupancy)
        bookingDisplay.bedSetup = booking.bedSetup ? booking.bedSetup : 'No bed setup info'
    } else {
        bookingDisplay.occupancyText = 'Unknown next arrival'
        bookingDisplay.occupancyState = occupancyToHuman(area.occupancy)
        bookingDisplay.bedSetup = 'No bed setup info'
    }

    if (!area.synced || !area.occupancy) {
        bookingDisplay.occupancyText = ''
        bookingDisplay.guestsInfo = ''
        bookingDisplay.bedSetup = ''
    }

    return bookingDisplay
}
