import React, { useContext, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { planState, waitingForUpgradeAtom } from '../state'
import Price from './Price'
import { AuthContext } from '../../../../../Auth/AuthContext'
import {
    ActionsWrapper,
    PlanContainer,
    BenefitsItem,
    BenefitsContainer,
    TrialDaysContainer,
    TrialDaysText,
    ActiveContainer,
    PricingDetails,
    PricingDeatilsText,
    PricingDeatilsAction
} from '../style'
import ActionButton from './ActionButton'
import { SubscriptionContext } from '../../../../../Subscriptions/SubscriptionContext'
import moment from 'moment-timezone'
import { numberFormat } from '../../../../../../utils/locale'
import ActivityView from '../../../../../../utils/loading-view'
import withErrorCatching from '../../../../../../utils/hocs/withErrorCatching'
import Alert from '../../../../../../components/alerts/Alert'
import { httpsCallable } from '../../../../../../api'

export const renderIcon = key => {
    if (key.includes('Pro')) {
        return '🏨'
    } else if (key.includes('Premium')) {
        return '🏢'
    } else if (key.includes('Basic')) {
        return '🏠'
    }
}

const Plan = ({
    planKey,
    plan,
    currencyCode,
    setShowPaymentModal,
    setSelectedPlan,
    index,
    plans,
    estimates,
    setSubscriptionChangeAction,
    catchError
}) => {
    const {
        currentOrganization: { subscriptionTrialDone, key, brand },
        orgSubscription
    } = useContext(AuthContext)
    const { currentSubscription } = useContext(SubscriptionContext)
    const [expiring, setExpiring] = useState(orgSubscription.expiresAt !== undefined)
    const { description, features, pricing } = useRecoilValue(planState(planKey))
    const [waitingForUpgrade, setWaitingForUpgrade] = useRecoilState(waitingForUpgradeAtom)
    const [dataLoading, setDataLoading] = useState(false)
    const [cancelSubscriptionModalIsOpen, setCancelSubscriptionModalIsOpen] = useState(false)

    const currentSubscriptionName = currentSubscription.name

    const pricingString = `${currencyCode}:month`
    const priceObj = pricing[pricingString]

    if (!priceObj || Object.keys(priceObj).length === 0) {
        return null
    }

    const isCurrentPlan = plan.key === currentSubscription.id

    const isPaidSubscription = plan.upgrades.length == 0 && currentSubscriptionName === planKey
    const isPro = plan.upgrades.length == 0

    const planHasTrial = priceObj.trial.available
    const canUpgrade = index > 0

    let price = priceObj.startingPrice || 0
    let estimate
    if (estimates && estimates.length > 0) {
        estimate = estimates.find(x => x.id === priceObj.id)
        if (estimate) {
            price = estimate.unitAmount
        }
    }

    const period = { periodNumber: priceObj.period, periodUnit: priceObj.periodUnit }

    const wrapperStyles = isPro ? ' my-md-n18 shadow-sm ribbon ribbon-left ' : ' shadow-sm ribbon'

    const isActiveSubscription = currentSubscriptionName.toLowerCase() === planKey.toLowerCase()

    const activeCondition = isActiveSubscription && (
        <ActiveContainer>
            <i className="fa fa-star text-dark-65"></i>&nbsp;&nbsp; Current subscription
        </ActiveContainer>
    )

    const renderFeatures = () => {
        const mapFeatures = features.map((feature, index) => {
            return (
                <BenefitsItem bold={index === 0 && isPro} key={feature.title + index}>
                    {feature.marketingTitle || feature.title}
                </BenefitsItem>
            )
        })

        return <BenefitsContainer key={index}>{mapFeatures}</BenefitsContainer>
    }

    const removeCancelSubscription = () => {
        setDataLoading(true)
        const removeSubscriptionChanges = httpsCallable('removeSubscriptionChanges')
        return removeSubscriptionChanges()
            .then(() => {
                setExpiring(false)
                setDataLoading(false)
            })
            .catch(error => {
                console.error('error', error)
            })
    }

    async function callCancel(orgKey, priceId, planKey, currencyCode) {
        const subscription = httpsCallable('updateSubscription')
        return subscription({ organizationKey: orgKey, priceId: priceId, planId: planKey, currencyCode })
    }

    const handleUpgrade = async () => {
        setCancelSubscriptionModalIsOpen(false)
        setSelectedPlan({ plan, priceObj })
        if (isCurrentPlan && plan.upgrades && plan.upgrades.length === 0) {
            const brandFilter = brand === 'sweeply' ? 'sweeply' : 'pronto'
            const basicPlan = plans.filter(x => x.group === brandFilter)[0]
            const basicPricingObject = basicPlan.pricing[pricingString]

            setWaitingForUpgrade(true)

            try {
                await callCancel(key, basicPricingObject.id, plan.key, currencyCode)
            } catch (error) {
                const message = error.code === 'internal' ? 'Something went wrong. Please try again or contact support.' : error.message

                console.error(error)
                catchError(message, { button: { text: 'Okay' }, contactSupportMessage: true })

                setWaitingForUpgrade(false)
                return
            }

            setSubscriptionChangeAction('cancel')
            setWaitingForUpgrade(false)
            setExpiring(true)
        } else {
            setShowPaymentModal(true)
        }
    }

    const currentSubscriptionIsProTrial = orgSubscription.status === 'trial' && orgSubscription.name.toLowerCase().includes('pro')
    const currentSubscriptionTrialDaysLeft = moment(orgSubscription.trialEnd).startOf('day').diff(moment().startOf('day'), 'days')

    const removeCancelSubscriptionCondition = expiring && isPro && isPaidSubscription && (
        <div className="d-flex flex-column align-items-center justify-content-center">
            {dataLoading && <ActivityView />}
            {!dataLoading && (
                <>
                    <span className="text-dark-65 mb-5">Your subscription is set to cancel at the end of the billing period.</span>
                    <ActionButton text={'Renew'} onClick={removeCancelSubscription} />
                </>
            )}
        </div>
    )
    const cancelSubscriptionBtnCondition = isPaidSubscription && isPro && !expiring && (
        <ActionButton text={'Cancel subscription'} onClick={() => setCancelSubscriptionModalIsOpen(true)} loading={waitingForUpgrade} />
    )

    const tryTrialBtnCondition = planHasTrial && !subscriptionTrialDone && canUpgrade && (
        <>{!subscriptionTrialDone && !isActiveSubscription && <ActionButton text={'Try for free'} onClick={handleUpgrade} />}</>
    )
    const upgradeButtonCondition = subscriptionTrialDone && canUpgrade && (
        <>{!isActiveSubscription && <ActionButton text={'Upgrade to Pro'} onClick={handleUpgrade} />}</>
    )

    const minimumPriceCondition = () => {
        if (plan.minUnits && plan.minUnits.length > 0) {
            return (
                <span className="d-flex text-left flex-column text-dark-50 font-size-sm px-5 py-10">
                    * Minimum price {numberFormat((plan.minUnits * priceObj.startingPrice) / 100, currencyCode)}
                </span>
            )
        }
    }

    return (
        <PlanContainer color={isPro ? '#8DD1E4' : '#FFDC30'} className={wrapperStyles}>
            {cancelSubscriptionModalIsOpen && (
                <Alert
                    title={'Subscription cancelling'}
                    text={'Are you sure you want to cancel your subscription?'}
                    onConfirm={handleUpgrade}
                    onCancel={() => setCancelSubscriptionModalIsOpen(false)}
                />
            )}

            {activeCondition}
            {/* {trailDaysLeftCondition} */}

            <div className="pt-5 pb-10 ">
                <h1 className="mb-5 d-flex">
                    {plan.name}&nbsp;
                    {renderIcon(planKey)}&nbsp;
                    {currentSubscriptionIsProTrial && isPro && (
                        <TrialDaysContainer>
                            <TrialDaysText>
                                Trial ends in {currentSubscriptionTrialDaysLeft}&nbsp;
                                {currentSubscriptionTrialDaysLeft === 1 ? 'day' : 'days'}
                            </TrialDaysText>
                        </TrialDaysContainer>
                    )}
                </h1>

                <Price
                    price={numberFormat(price / 100, currencyCode)}
                    isPro={isPro}
                    period={period}
                    isLineThrough={false}
                    hasExtraDetails={isPro}
                />

                <span className="d-flex text-left flex-column mt-3">{renderFeatures()}</span>
                {isPro && (
                    <PricingDetails>
                        <PricingDeatilsText>With more rooms per account, prices get lower. See the</PricingDeatilsText>{' '}
                        <PricingDeatilsAction onClick={() => window.open('https://getsweeply.com/pricing', '_blank')}>
                            pricing details
                        </PricingDeatilsAction>
                    </PricingDetails>
                )}
                {isPro && (
                    <ActionsWrapper className="mt-15 mb-5">
                        {cancelSubscriptionBtnCondition}
                        {tryTrialBtnCondition}
                        {upgradeButtonCondition}
                        {removeCancelSubscriptionCondition}
                    </ActionsWrapper>
                )}
                {minimumPriceCondition()}
            </div>
        </PlanContainer>
    )
}

export default withErrorCatching(Plan)
