import React, { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import Notifications from 'react-notify-toast'
import * as colors from '@shared/colors'
import * as issueData from '@shared/issue-data'

import { findLeadBooking } from '@shared/booking-service'

import * as brand from '@shared/brand'
import * as c from '@shared/constants'

import { getBookingDisplay } from '@shared/get-booking-display.js'
import firebase, { asFirebase, tasksRepo } from '../../../utils/firebase'
import { AuthContext } from 'app/modules/Auth/AuthContext'
import Issues from './issues'
import { useAreaSummaryFirestore } from '../useProjectionFirestore'
import { AreaStruct, BookingStruct, TaskStruct } from '@shared/dataObjects'
import { cleaningStatusToHuman } from '@shared/cleaning-helpers'
import { getNextBooking } from '@shared/booking-data'
import { TaskScreenContext } from './TaskScreenContext'
import { View } from 'react-native-web'
import LastHousekeeping from './last-housekeeping'
import Activities from './activities'
import BookingInfo from './booking-info'
import AssignedTo from './assigned-to'
import HousekeepingStatus from './housekeeping-status'
import BookingNotes from './booking-notes'
import Rule from './rule'
import ExtraServicesInfo from '../../housekeeping-detail/services'
import DailyComment from './daily-comment'
import Header from './header'
import CleaningSchedule from './cleaning-schedule'
import DeveloperSection from './developer-section'
import { Moment } from 'moment-timezone'
import ModalPage from './modal-page'
import { areaModalStyle } from '../../dashboard/styles'
import { ActivityIndicator } from 'react-native'
import { CloseModalButton } from '../../../components/buttons/close-modal-button'
import { MODAL_SECTION_VERTICAL_SPACE, MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE } from '../../../utils/constants'
import Checklist from './checklist'
import AreaNotes from './area-notes'
import { AreaSummaryProjectionStruct } from '@shared/firestore-structs'

interface AreaDisplay {
    taskName: string
}
const getAreaDisplay = (area: Partial<AreaStruct> & { currentTask: Partial<TaskStruct> & { color: string; icon: string } }) => {
    const areaDisplay: AreaDisplay = {
        taskName: cleaningStatusToHuman(area.cleaningStatus, area.occupancy)
    }

    if (area.currentTask) {
        areaDisplay.taskName = area.currentTask.name || ''
    }

    return areaDisplay
}

const getBookingDates = (checkinDate: Moment, checkoutDate: Moment) => {
    const dates: string[] = []
    while (checkinDate <= checkoutDate) {
        dates.push(moment(checkinDate).valueOf().toString())
        checkinDate.add(1, 'days')
    }
    return dates
}

const ProjectionsModal = (props: any) => {
    const { area, currentTask, setBookingDisplay, setDailyComment, setIssues, issues, setCurrentTask, setArea, setPriority } = useContext(
        TaskScreenContext
    )

    const { areaSummary, isLoading } = useAreaSummaryFirestore(props.area.key)

    const { currentUser, currentOrganization } = useContext(AuthContext)

    const [leadBooking, setLeadBooking] = useState<BookingStruct | null>(null)

    const [isFutureBooking, setIsFutureBooking] = useState(false)
    const [lastCleaningTask, setLastCleaningTask] = useState<(Pick<TaskStruct, 'cleaning' | 'assignedTo'> & { bedSetup?: string }) | null>(
        null
    )

    const [selectedDateNumber, setSelectedDateNumber] = useState(props.selectedDateNumber)
    const [areaAndBookingDataIsReady, setAreaAndBookingDataIsReady] = useState(false)

    const DATA_IS_READY = !!areaSummary
    // }

    useEffect(() => {
        if (areaSummary) {
            setArea(areaSummary?.area)
            prepareArea(areaSummary)
        }
    }, [areaSummary])

    useEffect(() => {
        const unsubscribeIssueListener = issueData
            .onFilteredIssues(asFirebase(firebase), currentUser!.organizationKey, [c.ISSUE_OPEN, c.ISSUE_ASSIGNED], props.area.key)
            .onSnapshot(onIssuesUpdate)

        return () => {
            unsubscribeIssueListener()
        }
    }, [])

    const onIssuesUpdate = (snap: any) => {
        // this.hashTags = []
        // this.setState({ issues: snap })
        setIssues(snap)
    }

    const prepareArea = async (areaSummary: AreaSummaryProjectionStruct) => {
        const bookings = areaSummary.bookings.map(x => {
            return { ...x, bookingDates: getBookingDates(moment(x.checkinDate), moment(x.checkoutDate)) }
        })
        let leadBooking = findLeadBooking({ occupancy: areaSummary.area.occupancy }, bookings as any, selectedDateNumber)

        let isFuture = true
        if (
            leadBooking &&
            getBookingDates(moment(leadBooking.checkinDate), moment(leadBooking.checkoutDate)).includes(selectedDateNumber.toString())
        ) {
            isFuture = false
        } else {
            const nextBookings = await getNextBooking(asFirebase(firebase), area.key, currentOrganization!.key, selectedDateNumber)
            leadBooking = nextBookings[0]
            isFuture = true
        }
        const bd = getBookingDisplay(leadBooking, area, moment(selectedDateNumber))
        setBookingDisplay(bd)
        setLeadBooking(leadBooking)
        setIsFutureBooking(isFuture)
        if (areaSummary.currentTask) {
            setCurrentTask(areaSummary.currentTask)
        }
        if (areaSummary.dailyComments.length > 0) {
            setDailyComment(areaSummary?.dailyComments[0])
        }
        if (areaSummary.lastHousekeeping) {
            setLastCleaningTask(areaSummary.lastHousekeeping)
        }
        setPriority(!!areaSummary.priority)
        setAreaAndBookingDataIsReady(true)
    }

    // const DATA_IS_READY = this.state.dataIsReady
    const IS_AREA_SYNCED = area.synced
    const SHOW_BOOKING_NOTES =
        areaSummary && areaSummary.bookingNotes !== undefined && areaSummary.bookingNotes !== null && areaSummary.bookingNotes !== ''

    const HAS_CLEANING_OBJ = lastCleaningTask && lastCleaningTask.cleaning
    const HAS_ACTIVITY = areaSummary && areaSummary.activities && areaSummary.activities.length > 0
    const extrasToday = areaSummary && areaSummary.extras ? areaSummary.extras.filter((x: any) => x.date === selectedDateNumber) : []
    const HAS_EXTRAS = extrasToday && extrasToday.length > 0

    const SHOW_ISSUES = issues && issues.length > 0 //false //this.state.issues && this.state.issues.length > 0
    const SHOW_RULE = !!(areaSummary && areaSummary.activeRule)
    const HAS_TASK_CHECKLIST = currentTask && currentTask.key && currentTask.checklist && currentTask.checklist.length > 0

    const IS_OPT_IN = currentOrganization!.allowOptIn || currentOrganization!.allowOptOut

    const DEBUG_BORDER = 0
    const min_title_box_height = 250

    const sections = [<DailyComment key={'daily-comment'} />]
    if (HAS_EXTRAS) {
        sections.push(<ExtraServicesInfo extras={areaSummary!.extras!} />)
    }
    sections.push(<AreaNotes />)
    if (SHOW_BOOKING_NOTES) {
        sections.push(<BookingNotes />)
    }
    if (IS_OPT_IN && IS_AREA_SYNCED && leadBooking) {
        sections.unshift(<CleaningSchedule leadBooking={leadBooking} />)
    }
    if (SHOW_RULE) {
        sections.push(<Rule activeRule={areaSummary.activeRule} />)
    }
    if (SHOW_RULE && HAS_TASK_CHECKLIST) {
        sections.push(<Checklist />)
    }
    if (HAS_CLEANING_OBJ) {
        sections.push(<LastHousekeeping lastCleaningTask={lastCleaningTask} />)
    }
    if (SHOW_ISSUES) {
        sections.push(<Issues issues={issues} />)
    }
    if (HAS_ACTIVITY) {
        sections.push(<Activities activities={areaSummary.activities} />)
    }

    return (
        <View
            style={{
                flexDirection: 'column',
                minHeight: areaModalStyle.content.maxHeight,
                padding: 0,
                marginBottom: 40
            }}>
            <ModalPage />
            {!DATA_IS_READY && (
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <ActivityIndicator size="large" color={brand.getBrand().navBarColor} />
                </View>
            )}
            <Notifications options={{ animationDuration: 1000, top: '45%' }} />

            <View
                style={{
                    zIndex: 99,
                    backgroundColor: colors.white,
                    // position: 'sticky',
                    top: 0,
                    left: 0
                }}>
                <CloseModalButton close={props.closeModal} isWhite={true} />
                <Header dataIsReady={DATA_IS_READY} />
            </View>

            <View style={{ backgroundColor: colors.white }}>
                <View
                    style={{
                        borderColor: 'red',
                        borderWidth: 0,
                        flexDirection: 'row',
                        paddingHorizontal: MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                        marginTop: MODAL_SECTION_VERTICAL_SPACE + 6,
                        marginBottom: MODAL_SECTION_VERTICAL_SPACE + 6
                    }}>
                    <View style={{ borderColor: 'red', borderWidth: 0, width: '50%' }}>
                        <BookingInfo isFutureBooking={isFutureBooking} />
                        <AssignedTo />
                    </View>
                    <View style={{ borderColor: 'red', borderWidth: 0, width: '50%', zIndex: 100, alignItems: 'flex-end' }}>
                        <HousekeepingStatus />
                    </View>
                </View>

                {DATA_IS_READY &&
                    sections.map((component, i) => {
                        return (
                            <View
                                key={i}
                                style={{
                                    marginTop: 0,
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    paddingHorizontal: MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                                    paddingTop: MODAL_SECTION_VERTICAL_SPACE,
                                    paddingBottom: 24,
                                    backgroundColor: i % 2 ? colors.white : colors.gentle_gray_spectaflow
                                }}>
                                {component}
                            </View>
                        )
                    })}

                {DATA_IS_READY && currentUser!.authSuper && (
                    <View
                        style={{
                            borderColor: 'red',
                            borderWidth: DEBUG_BORDER,
                            flexDirection: 'row',
                            paddingHorizontal: MODAL_SIDE_TO_CONTENT_HORIZONTAL_SPACE,
                            marginBottom: MODAL_SECTION_VERTICAL_SPACE
                        }}>
                        <DeveloperSection />
                    </View>
                )}
            </View>
        </View>
    )
}

export default ProjectionsModal
