import { Firebase } from './firebase'

export async function getDailyComment(firebase: Firebase, areaKey: string, date: number) {
    const dailyCommentsRef = await firebase
        .firestore()
        .collection('areas')
        .doc(areaKey)
        .collection('dailyComments')
        .where('date', '==', date)
        .get()

    if (dailyCommentsRef.docs.length > 0) {
        return dailyCommentsRef.docs[0].data()
    }

    return null
}

export function getDailyCommentsQuery(firebase: Firebase, organizationKey: string, date: number, areaKey: string | null = null) {
    let dailyCommentsQuery = firebase
        .firestore()
        .collectionGroup('dailyComments')
        .where('organizationKey', '==', organizationKey)
        .where('date', '==', date)

    if (areaKey) {
        dailyCommentsQuery = dailyCommentsQuery.where('areaKey', '==', areaKey)
    }

    return dailyCommentsQuery
}
