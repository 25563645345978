import * as ic from './img-constants'
import * as c from './txt-constants'

export function getStatusImage(status) {
    let statusImgName = ic.IMG_STATUS_ANY
    if (status === c.ISSUE_ANYSTATUS) {
        statusImgName = ic.IMG_STATUS_ANY
    } else if (status === c.ISSUE_OPEN) {
        statusImgName = ic.IMG_STATUS_OPEN
    } else if (status === c.ISSUE_ASSIGNED) {
        statusImgName = ic.IMG_STATUS_ASSIGNED
    } else if (status === c.ISSUE_COMPLETE) {
        statusImgName = ic.IMG_STATUS_COMPLETE
    } else if (status === c.ISSUE_DELETED) {
        statusImgName = ic.IMG_STATUS_DELETED
    }
    return statusImgName
}
