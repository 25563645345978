export enum LoginTypesEnum {
    withLastNameAndRoomName = 'withLastNameAndRoomName'
}

export type LoginTypes = keyof typeof LoginTypesEnum

export interface GuestLoginWithLastNameAndRoomNamePayload {
    lastName: string
    roomName: string
    checkInDate: string
}
