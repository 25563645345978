import { selector, selectorFamily } from 'recoil'
import { calculatedAreaSelector } from './calculatedAreaData'
import {
    pickAreaNoteIcon,
    pickCheckinIcon,
    pickCleaningActionIcon,
    pickCleaningStatusColor,
    pickCleaningStatusIcon,
    pickInspectionIcon,
    pickPauseIcon,
    pickPeopleIcon
} from '@shared/cleaning-helpers'
import * as colors from '@shared/colors'
import dailyCommentImg from '@shared/img/cleaning-status/daily-comment-white.svg'
import { dailyCommentAtom, rulesAtom } from '../atoms'

/*
    TODO: make proper type after rewriting "pickIcon" functions to TS
*/
export type HousekeepingIconType = { source: string | null; width?: number | null; height?: number | null } | null | undefined

export const cleaningStatusColorSelector = selector<string>({
    key: 'housekeeping-modal-cleaningStatusColor',
    get: ({ get }) => {
        const {
            area: { synced, dormant, cleaningStatus, occupancy }
        } = get(calculatedAreaSelector)

        if (synced && !dormant) {
            return pickCleaningStatusColor(cleaningStatus, occupancy)
        }

        return colors.gray
    }
})

export const housekeepingIconsSelector = selectorFamily<HousekeepingIconType[], boolean>({
    key: 'housekeeping-modal-housekeepingIcons',
    get: useCleaningStatusColor => ({ get }) => {
        const {
            area: { cleaningStatus, occupancy, cleaningFrequency, guestCheckedIn, note }
        } = get(calculatedAreaSelector)
        const rules = get(rulesAtom)
        const dailyComment = get(dailyCommentAtom)

        const cleaningActionIcon = pickCleaningActionIcon(cleaningStatus, occupancy, cleaningFrequency, rules, useCleaningStatusColor)
        const cleaningStatusIcon = pickCleaningStatusIcon(cleaningStatus, useCleaningStatusColor)
        const checkInIcon = pickCheckinIcon(cleaningStatus, occupancy, useCleaningStatusColor)
        const guestsIcon = pickPeopleIcon(cleaningStatus, occupancy, guestCheckedIn, useCleaningStatusColor)
        const pauseIcon = pickPauseIcon(cleaningStatus, useCleaningStatusColor)
        const inspectionIcon = pickInspectionIcon(cleaningStatus, useCleaningStatusColor)
        const areaNoteIcon = note && note.length > 0 ? { source: pickAreaNoteIcon(null, null), width: 18, height: 18 } : null
        const dailyCommentIcon =
            dailyComment && dailyComment.comment.length > 0 ? { source: dailyCommentImg as string, width: 18, height: 18 } : null

        return [
            cleaningActionIcon,
            cleaningStatusIcon,
            checkInIcon,
            guestsIcon,
            pauseIcon,
            inspectionIcon,
            areaNoteIcon,
            dailyCommentIcon
        ] as HousekeepingIconType[]
    }
})
