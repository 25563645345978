import React, { useContext, useState } from 'react'

import * as c from '@shared/constants'
import moment from 'moment/moment'
import { DashboardContext } from './DashboardContext'
import SearchInput from '../../components/forms/Search'
import DatePicker from '../../components/hoverable-date-picker'
import { PriorityButton } from '../../components/priority-button'
import { SubscriptionCheck } from '../../components/upgrade-signals'

import DailyCommentSummaryButton from '../daily-comments-summary-button'
import StatusPopup from '../houskeeping-status-popup'
import OccupancyFilterPopup from '../occupancy-filter-popup'

const ProjectionsDashboardFilter = ({ areas }: { areas: any[] }) => {
    const {
        searchValue,
        setSelectedDateNumber,
        setShowDailyCommentSummaryModal,
        setShowActionBar,
        showActionBar,
        setPriorityFilter,
        priorityFilter,
        setShowUpgradeMessage,
        setShowQuickGuideModal,
        setCleaningStatusFilter,
        occupancyFilter,
        setOccupancyFilter,
        cleaningStatusFilter,
        setSearchValue
    } = useContext(DashboardContext)
    const openDailyCommentsSummary = () => {
        setShowDailyCommentSummaryModal(true)
    }

    const pickAssignMode = () => {
        setShowActionBar(!showActionBar)
    }

    const onPriorityPressTimeout = () => {
        setPriorityFilter(!priorityFilter)
    }

    const openUpgradeMessage = () => {
        setShowUpgradeMessage(true)
    }

    const onSearch = (value: string) => {
        setSearchValue(value)
    }

    const openQuickGuide = () => {
        setShowQuickGuideModal(true)
    }

    const [selectedDate, setSelectedDate] = useState(moment())

    const onDateChange = (date: any) => {
        console.log('onDateChange', moment(date).format('YYYY-MM-DD'))
        setSelectedDate(moment(date))
        setSelectedDateNumber(moment(date).valueOf())
    }

    return (
        <div className="row row-paddingless align-items-start" style={{ position: 'relative', zIndex: 2 }}>
            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
                <SearchInput value={searchValue} onChange={onSearch} placeholder={'Units, assignees and more'} />
            </div>

            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10 d-flex align-items-center justify-content-center">
                <div style={{ height: 22, width: 22 }} />

                <SubscriptionCheck
                    featureId="browse-history"
                    onAvailable={() => (
                        <DatePicker hasTodayButton selectedDateNumber={selectedDate.valueOf()} onDateChange={onDateChange} />
                    )}
                    onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                        return (
                            <DatePicker
                                hasTodayButton
                                selectedDateNumber={selectedDate.valueOf()}
                                onDateChange={() => {
                                    openUpgradeMessage()
                                }}
                            />
                        )
                    }}
                />
            </div>
            <div className="col-xs-12 col-md-4 my-sm-3 my-xs-10">
                <div className="container" style={{ paddingRight: 22, paddingLeft: 90 }}>
                    <div className="row justify-content-between">
                        <PriorityButton
                            onPress={onPriorityPressTimeout}
                            priority={priorityFilter}
                            count={areas.filter((area: any) => area.priority).length}
                            subscriptonAccess={true}
                        />

                        <SubscriptionCheck
                            featureId="daily-comments"
                            onAvailable={() => (
                                <DailyCommentSummaryButton
                                    onPress={openDailyCommentsSummary}
                                    count={
                                        areas.filter((area: any) => area.dailyComments.length > 0)
                                            ? areas.filter((area: any) => area.dailyComments.length > 0).length
                                            : 0
                                    }
                                />
                            )}
                            onNotAvailable={(notAccessibleFeature, availableUpgrades) => {
                                return (
                                    <DailyCommentSummaryButton
                                        onPress={() => {
                                            openUpgradeMessage()
                                        }}
                                        count={
                                            areas.filter((area: any) => area.dailyComments.length > 0)
                                                ? areas.filter((area: any) => area.dailyComments.length > 0).length
                                                : 0
                                        }
                                    />
                                )
                            }}
                        />

                        <StatusPopup setStatusFilter={setCleaningStatusFilter} defaultFilter={null} statusFilter={cleaningStatusFilter} />

                        <OccupancyFilterPopup
                            setOccupancyFilter={setOccupancyFilter}
                            occupancy={occupancyFilter}
                            options={[
                                { occupancy: c.OCCUPANCY_ALL },
                                { occupancy: c.OCCUPANCY_CHECKIN },
                                { occupancy: c.OCCUPANCY_STAYOVER },
                                { occupancy: c.OCCUPANCY_STAYOVER_80 },
                                { occupancy: c.OCCUPANCY_CHECKOUT },
                                { occupancy: c.OCCUPANCY_VACANT },
                                { occupancy: c.OCCUPANCY_TURNOVER },
                                { occupancy: 'reset' }
                            ]}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectionsDashboardFilter
