import moment from 'moment'
import React, { createContext, useState } from 'react'
import { CLEANING_ACTION_PLAY } from '../../../shared/txt-constants'
import { getCleaningTaskObject } from '../../../shared/dataObjects'

export const TaskScreenContext = createContext()

const initArea = {
    id: '',
    name: '',
    description: '',
    address: '',
    group: '',
    displayCleaningStatus: true
}

export const initCleaningObject = {
    start: null,
    end: null,
    playOrPause: CLEANING_ACTION_PLAY,
    play: [],
    pause: [],
    stop: []
}

export const TaskScreenProvider = props => {
    if (!props.area) {
        return null
    }
    console.log('Init task screen provider', props.area.name)
    const ct = getCleaningTaskObject(props.currentUser, props.area, null, null, props.area.activeRule)

    const [area, setArea] = useState(props.area)
    const [activities, setActivities] = useState(props.area.activities || [])
    // const [dailyComments, setDailyComments] = useState(params.area.dailyComment ? [params.area.dailyComment] : [])

    // const [currentTask, setCurrentTask] = useState(params.area.currentTask || getCleaningTaskObject(params.currentUser, params.area))
    const [currentTask, setCurrentTask] = useState(ct)
    const [cleaningObject, setCleaningObject] = useState(initCleaningObject)
    const [bookingDisplay, setBookingDisplay] = useState(null)

    const [counter, setCounter] = useState(0)

    const [showUsersModal, setShowUsersModal] = useState(false)
    const [showOccupancyModal, setShowOccupancyModal] = useState(false)
    const [showMoreModal, setShowMoreModal] = useState(false)
    const [showCleaningStatusModal, setShowCleaningStatusModal] = useState(false)
    const [showAssignedTo, setShowAssignedTo] = useState(false)

    const [notifications, setNotifications] = useState([])

    const [priority, setPriority] = useState(false)
    const [occupancy, setOccupancy] = useState(props.area.occupancy)
    const [dailyComment, setDailyComment] = useState(props.area.dailyComment)
    const [assignedTo, setAssignedTo] = useState([])
    const [cleaningStatus, setCleaningStatus] = useState(props.area.cleaningStatus)
    const [issues, setIssues] = useState([])

    const [users, setUsers] = useState([])
    const [usersOptions, setUsersOptions] = useState([])

    const [selectedDateNumber, setSelectedDateNumber] = useState(props.selectedDateNumber || moment().startOf('day').valueOf())

    const [tasksList, setTasksList] = useState(props.area.tasks || [])

    const [displaySettings, setDisplaySettings] = useState()
    const [showUpgradeMessage, setShowUpgradeMessage] = useState(false)

    const [showIssueModal, setShowIssueModal] = useState(false)
    const [currentIssue, setCurrentIssue] = useState()

    const closeUpgradeMessage = () => {
        setShowUpgradeMessage(false)
    }

    const openUpgradeMessage = () => {
        setShowUpgradeMessage(true)
    }

    const value = {
        area,
        setArea,
        showUsersModal,
        setShowUsersModal,
        showOccupancyModal,
        setShowOccupancyModal,
        showMoreModal,
        setShowMoreModal,
        showCleaningStatusModal,
        setShowCleaningStatusModal,
        showAssignedTo,
        setShowAssignedTo,
        notifications,
        setNotifications,
        selectedDateNumber,
        setSelectedDateNumber,
        activities,
        setActivities,
        bookingDisplay,
        setBookingDisplay,
        currentTask,
        setCurrentTask,
        cleaningObject,
        setCleaningObject,
        priority,
        setPriority,
        occupancy,
        setOccupancy,
        dailyComment,
        setDailyComment,
        assignedTo,
        setAssignedTo,
        cleaningStatus,
        setCleaningStatus,
        initArea,
        counter,
        setCounter,
        tasksList,
        setTasksList,
        displaySettings,
        setDisplaySettings,
        issues,
        setIssues,
        users,
        setUsers,
        usersOptions,
        setUsersOptions,
        showUpgradeMessage,
        setShowUpgradeMessage,
        showIssueModal,
        setShowIssueModal,
        openUpgradeMessage,
        closeUpgradeMessage,
        currentIssue,
        setCurrentIssue
    }

    return (
        <TaskScreenContext.Provider value={value} {...props}>
            {props.children}
        </TaskScreenContext.Provider>
    )
}
